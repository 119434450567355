:root {
    --primary-color: #363e4b;
    --secondary-color: #1a1a1a;
    --cyan-color: #50aab3;
    --background-color: #ffffff;
    --text-color: #cfcfcf;
}

body.light {
    --primary-color: #ececec;
    --secondary-color: #dfdfdf;
    --background-color: #ffffff;
    --cyan-color: #50aab3;
    --text-color: #1a1a1a;
}

.white-color{
    color:#fff
}

.light .text-light {
    color: var(--text-color) !important;
}

.light .profile-user-name-card  , .light .home-card{
    background: linear-gradient(rgba(236, 236, 236, 0.8), rgba(255, 255, 255, 0.7));
}
.light .home-card:hover{
    background: linear-gradient(rgba(216, 216, 216, 0.8), rgba(238, 238, 238, 0.7));
}


.light .text-faded {
    color: var(--text-color) !important;
}

.light .text-faded2 {
    color: var(--text-color) !important;
}

.light #login-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 1;
}

.light .login {
    z-index: 2;
}
.light .movie-details {
    background: #ffffffdd;
}
.text-color{
    color: var(--text-color);
}

.leaderboard-tabs button {
    background-color: var(--primary-color) !important;
    color:var(--text-color);
}

.audience-tabs button{
    margin-bottom: 0!important;
}


/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
    box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
    margin: 0;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body {
    height: 100%;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    background-color: var(--primary-color);
}

/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
    font: inherit;
}

/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

/*
  9. Create a root stacking context
*/
#root,
#__next {
    isolation: isolate;
}

.logo{
    height: 80%;
}
.home-section .row {
    margin: 0;
}

.home-section .movies-row ,.home-section , #login-page{
    height: calc(100vh - 120px);
    background-color: var(--secondary-color);
    display: flex;
    justify-content: space-between;
    position: relative;
}



.home-section .movies-row {
    width: 100%
}





.nav-row {
    height: 60px;
    background-color: var(--primary-color);
}
.top-nav svg{
    width: 32px;
    height: 32px;
}

.nav-row .active svg{
    fill:var(--cyan-color)
}
/* .top-nav{
    background-color: #161616;
} */

.nav-row .custom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.nav-row input {
    width: 40%;
    text-align: center;
    border-bottom: 1px solid var(--secondary-color);
    color: var(--secondary-color);
}

.nav-row i {
    font-size: xx-large;
    color: var(--secondary-color);
}




.home-section .movies-row .poster-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%
}

.home-section .movies-row .poster-section i {
    cursor: pointer;
}

.ad{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
}
.home-section .movies-row .poster {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.home-section .movies-row .poster-skeleton {
    height: 100%;
    width: 100%;
}

.home-section .movies-row .vs-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--secondary-color);
    padding: 0 40px;
    width: 20%
}

.home-section .movies-row .vs-section h1 {
    font-size: 80px;
        width: 180px;
        height: 180px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
}

.home-section .movies-row .vs-section i {
    font-size: 100px;
}

.details-icon {
    position: absolute;
    top: 5%;
    left: 25px;
    font-size: xx-large;
    cursor: pointer;

}
.exit-search-icon {
    position: absolute;
    top: 5%;
    right: 25px;
    font-size: xx-large;
    cursor: pointer;
}
.hide-icon {
    font-size: xx-large;
    cursor: pointer;
    color: var(--secondary-color);

}

.movie-details {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--secondary-color);
    background: #000000dd;

    /* background: linear-gradient(0deg, rgb(44, 44, 44) 0%rgba(138, 138, 138, 0.803)3) 100%); */
    color: var(--secondary-color);
    padding: 30px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.movie-details .thumb {
    height: 180px;
    object-fit: cover;
}

.movie-details .detail-item {
    margin: 0;
}

.movie-details .detail-item span {
    font-weight: bolder;
}

.movie-detail-header {
    font-size: xx-large;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 15px;
    padding-top: 10px;
}

.movie-detail-header i {
    cursor: pointer;
}

.movie-detail-footer .btn {
    width: 100%;
    /* border-radius: 100px; */
    display: flex;
    justify-content: center;
    margin-top: 15px;
    padding: 10px;
}

.movie-detail-footer .blue-btn {
    border: 1px solid #01afe9;
    color: #01afe9;
}

.movie-detail-footer .blue-btn:hover {
    background-color: #01afe9;
    color: var(--text-color);
}

.movie-detail-footer .gold-btn {
    border: 1px solid #d9a404;
    background-color: #d9a404;
    color: #04041a;
}



/* .movie-detail-footer .btn img {
    height: 20px;
    margin-right: 10px;
} */

.movie-detail-body {
    height: 100%;
    overflow-y: scroll
}


.home-section .nav-row {
    position: fixed;
    width: 100%;
}

.search-poster-image{
    height: 70px;
    width: 50px;
    object-fit: cover;
    background: #ececec;
}

.leaderboard-section{
    height: calc(100vh - 120px);
    background-color: var(--secondary-color);
    overflow: scroll;
}

.leaderboard-cell{
    background-color: whitesmoke;
    color: #222222;
    display: flex;
    align-items: center;
    margin: 0 2px;
    padding: 2px 2px;
}
.leaderboard-cell p{
    color: #6e6e6e;
    font-size: small;
}
.leaderboard-row:hover .leaderboard-cell p{
    color: var(--text-color)fff;
}

.leader-cell{
    background-color: #f8f8f8;
}

.leader-cell-dark{
    background-color: var(--primary-color);
}


.leader-h5{
    display: flex;
}

.leader-p{
    font-size: small;
}
.movie-cell{
    width: 100%;
}

.movie-cell img{
    width: 50px;
    height: 70px;
    object-fit: cover;
    margin-right: 10px;
}
.score-cell{
    width: 220px;
    justify-content: center;
    font-weight: bolder;
    font-size: medium;
}
.id-cell{
    width: 120px;
    justify-content: center;
    font-size: medium;
    font-weight: bolder;
}

.leaderboard-row{
    margin-bottom: 4px;
}



.leaderboard-row:hover .leader-cell{
    background-color: var(--primary-color);
    color: var(--text-color)!important;
    cursor: pointer;
}
.leaderboard-row:hover .leader-p{
    color: var(--text-color)!important;
}

.trophy-icon{
    width: 20px!important;
    height: auto!important;
    margin-left: 10px;
}
.movie-winner-select-trophy-icon{
    width: 75px!important;
    height: auto!important;
    margin-bottom: 10px;
}


.select-movie-btn {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000c4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--text-color);
    cursor: pointer;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.2s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 600px) {
    .nav-row {
            position: fixed;
            width: 100vw;
        }
    .movies-row{
        flex-direction: column;
    }
        .home-section .movies-row .poster {
            width: 100vw;
        }
}

.primary-color{
    color: var(--cyan-color);
}
.gold-color{
    color: #d4af37;
}

.silver-color{
    color: #C0C0C0;
}

.bronze-color{
    color: #CD7F32;
}

.skip-movies-btn{
    background-color: var(--primary-color)!important;
    color: var(--text-color)!important;
}

.hot-keys{
    color: var(--text-color)!important;
}

.movie-poster-toast{
    height: 80px!important;
    width: 60px!important;
    object-fit: cover!important;
}

.movie-bottom-info{
    position: absolute;
    bottom: 0px;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
    padding: 20px;
    width: 100%;
    height: 300px;
}
.leaderboard-movie-bg{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.leader-board-filter .custom_field{
    width: 200px!important;
}
.leader-board-filter fieldset{
    border-color: var(--text-color)!important;
}
.leader-board-filter label , .leader-board-filter svg , .leader-board-filter input , .leader-board-filter #demo-simple-select{
    color: var(--text-color)!important;
}
.leader-board-filter label {
    background-color: var(--secondary-color)!important;
}

.home-card{
    height: 140px;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7));
    padding: 0 25px;
    text-decoration: none!important;
}
.bio-card{
    height: 280px;
    margin-bottom: 20px;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7));
    padding: 25px;
    border-radius: 15px;
}
/* .bio-card input{
    height: 170px;

} */

.profile-user-name-card{
    /* height: 140px; */
    padding: 50px 25!important;
    margin-bottom: 20px;
    display: flex;
    justify-content: start;
    flex-direction: column;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7));    padding: 0 25px;
    border-radius: 15px;
    height: 100%;
    max-height: 100%;

}


.friends-col{
    overflow-y: scroll;
    height: 100%;
}

.profile-avatar-card{
    /* margin-bottom: 20px; */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none !important;
    background-size: cover;
    background-position: center;
}

.edit-icon{
    cursor: pointer;
}
.avatar-image-profile{
    height: 70px;
    width: 70px;
    border-radius: 100%;
}

.friend-card h1{
    font-size:medium;
}

.friend-card{

    height: auto;
    /* padding: 5px 15px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;
}

.friend-card h1{
    
    font-size:small;
    color: #eeeeee;
    text-align: center;
    margin: 0 10px;
}
.text-faded{
    color: #dddddd;
}
.text-faded2{
    color: #989898;
}

.max-3-lines{
    padding: 0;
    margin: 0;
        max-height: calc(1.5em * 3);
            /* Change 1.2em to the line height of your paragraph */
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* Number of lines to show */
            -webkit-box-orient: vertical;
}
.profile-top-image{
    height: 50vh;
    border-radius: 20px;
    object-fit: cover;
}

.profile-top-counter{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--cyan-color);
    border: 2px solid var(--text-color);
    color: var(--text-color);
    margin-top: -20px;
}
.friend-avatar-image-profile{
    height: 40px;
    width: 40px;
    border-radius: 100%;
}

.home-card:hover {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}

.home-card h1{
    color: var(--text-color);
}

.profile-edit-input{
    background-color: transparent;
    color: var(--cyan-color);
    border: 1px solid var(--cyan-color);
    padding: 15px;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    margin-bottom: 8px;
}

.movie-bttom-score{
    position: absolute;
    bottom: -58px;
    background-color: var(--secondary-color);
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    color: var(--text-color);
}

/* .trailer-lightbox iframe{
    width: 1000px;
    height: calc(100% - 200px);
    overflow: hidden;
}

.video-player-frame{
    width: 100%!important;
} */





.leaderboard-tabs button{
    background-color: var(--text-color);
    height: 35px;
    border-radius: 0;
    margin-bottom: 10px;
    border: 0;
}

.leaderboard-tabs .active {
    background-color: var(--cyan-color);
    color: var(--cyan-color);
    font-weight: bolder;
}


/* .home-leaderboard-section{
    height: 100%;
    overflow-y: scroll;
} */


.home-leaderboard-section .leaderboard-table{
    height: calc(100vh - 289px);
    overflow-y: scroll;
}

.home-btns-div{
    padding-top: 55px;
}

a{
    text-decoration: none;
    color: var(--cyan-color);
}

.country_select{
    border: 0;
    outline: "none"
}

.signup{
    width: 680px;
}

.profile-btn button , .profile-btn input{
    border-color: var(--cyan-color)!important;
    color: var(--cyan-color)!important;
}

.profile-btn svg{
    color: var(--cyan-color)!important;
}


.profile-btn2 button{
    border-color: var(--cyan-color)!important;
    background-color: var(--cyan-color)!important;
    color: var(--text-color)!important;
}

.text-primary{
    color:var(--cyan-color)!important
}



.movie-bttom-score #movie-title{
    font-size: small;
}

/* Hide the default scrollbar */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--secondary-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--cyan-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--cyan-color);
}

.top-genre-row{
    background-color: var(--secondary-color);
    border-radius: 10px;
    padding: 10px 20px;
}

.link{
    cursor: pointer;
}

.link:hover{
    color: var(--cyan-color);
}

.vs-image{
    border-radius: 100%;
    margin-bottom: 20px;
}


.advertise-placeholder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--text-color);
    border: 1px dashed var(--text-color);
    font-weight: 800;
}

.yes-no-btns-section{
    width: 100%;
    background: linear-gradient(to top,
                rgba(0, 0, 0, 0.9),
                /* Darker at the bottom */
                rgba(0, 0, 0, 0.6),
                /* Midway point */
                rgba(0, 0, 0, 0)
                /* Transparent at the top */
            );
}

.yes-no-btn{
    background: transparent;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 22px;
    font-weight: 800;
    height: 100px;
}

.yes-btn:hover{
    color: #04AA6D;
}

.no-btn:hover{
    color: #a10404;
}

.watch-btn:hover{
    color: #00f7ff;
}

.yes-no-card{
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7));
}



.slide-right {
    animation: slideToRight 0.5s ease forwards;
}

@keyframes slideToRight {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(600px);
        opacity: 0.1;
        width: 100px;
        height: 200px;
        object-fit: cover;
    }
}

.slide-left {
    animation: slideToLeft 0.5s ease forwards;
}

@keyframes slideToLeft {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(-600px);
        opacity: 0.1;
        width: 100px;
        height: 200px;
        object-fit: cover;
    }
}

.slide-up {
    animation: slideToUp 0.5s ease forwards;
}

@keyframes slideToUp {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-600px);
        opacity: 0.1;
        width: 100px;
        height: 200px;
        object-fit: cover;
    }
}

